<template>
<div>
  <HeroCurved :heading="$t('toolkit.title')" :button="false"/>

  <div class="container pt-5 pb-5">
    <div class="row">
      <div class="col-12 col-md-6 mb-5 mb-sm-4" v-lazy-container="{ selector: 'img' }">
        <b-img style="width: 65%" data-src="https://images.anytask.com/image/upload/urbiknsbdw7r5mpntg5w.svg" fluid alt="SVG/PNG Logo"/>
        <h4 class="mt-2">{{$t('toolkit.logo_transparent')}}</h4>
        <div class="row pt-3">
          <div class="col-6 pr-0">
            <a :href="svg.href" :download="svg.href" class="btn btn-primary rounded-pill">
              <span class="fa-solid fa-download shadow-sm pr-1"/>
              {{ svg.text }}
            </a>
          </div>
          <div class="col-6 pl-0">
            <a :href="png.href" target="_blank" class="btn btn-primary rounded-pill" v-b-popover.hover.top="$t('brand_asset_width')">
              <span class="fa-solid fa-download shadow-sm pr-1"/>
              {{ png.text }}
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6" v-lazy-container="{ selector: 'img' }">
        <b-img style="width: 65%" data-src="https://images.anytask.com/image/upload/c_scale,w_800/urbiknsbdw7r5mpntg5w.jpg" fluid alt="JPG Logo"/>
        <h4 class="mt-2">{{$t('toolkit.logo_white_background')}}</h4>
        <div class="row pt-3">
          <div class="col-12 pr-0">
            <a :href="jpg.href" target="_blank" class="btn btn-primary rounded-pill" v-b-popover.hover.top="$t('brand_asset_width')">
              <span class="fa-solid fa-download shadow-sm pr-1"/>
              {{ jpg.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <p class="pl-3 text-left">{{ $t('toolkit.welcome') }}</p>
    <ol class="text-left">
      <li>{{ $t('toolkit.point.1') }}</li>
      <li class="pt-2">{{ $t('toolkit.point.2') }}</li>
      <li class="pt-2">{{ $t('toolkit.point.3') }}</li>
      <li class="pt-2">{{ $t('toolkit.point.4') }}</li>
      <li class="pt-2">{{ $t('toolkit.point.5') }}</li>
      <li class="pt-2">{{ $t('toolkit.point.6') }}</li>
      <li class="pt-2" v-html="$t('toolkit.point.7')"></li>
      <li class="pt-2">{{ $t('toolkit.point.8') }}</li>
    </ol>
  </div>

</div>
</template>

<script>
import HeroCurved from '../components/Global/HeroCurved.vue'

export default {
  name: 'Toolkit',
  components: {
    HeroCurved
  },
  data () {
    return {
      svg: {
        href: 'https://images.anytask.com/image/upload/urbiknsbdw7r5mpntg5w.svg',
        text: '.svg'
      },
      jpg: {
        href: 'https://images.anytask.com/image/upload/c_scale,w_800/urbiknsbdw7r5mpntg5w.jpg',
        text: '.jpg'
      },
      png: {
        href: 'https://images.anytask.com/image/upload/c_scale,w_800/urbiknsbdw7r5mpntg5w.png',
        text: '.png'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  #content {
    background-color: white !important;
  }

  .hero {
    background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.25) ), url('~@/assets/BrandToolkit/brand-toolkit-mobile.jpg');
    @include sm-up {
      background-image: url('~@/assets/BrandToolkit/brand-toolkit-small.jpg');
    }
    @include md-up {
      background-image: url('~@/assets/BrandToolkit/brand-toolkit-medium.jpg');
    }
    @include lg-up {
      background-image: url('~@/assets/BrandToolkit/brand-toolkit-large.jpg');
    }
  }
</style>
